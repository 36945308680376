<nav class="sidebar">
  <div class="sidebar-header">

    <!--- Logo -->
    <!--<a routerLink="/" class="sidebar-brand nobleui-logo">
      {{appName}}
    </a>-->

    <a routerLink="/" class="sidebar-brand nobleui-logo">
     <img [src]="logo" alt="logo">
   </a>

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <span></span>
      <span></span>
      <span></span>
    </div>

  </div>
  <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()" [perfectScrollbar]>

    <!--- Sidemenu start -->

    <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
      <ng-container *ngFor="let item of menuItems">
        <li class="nav-item nav-category" *ngIf="item.isTitle">{{ lang !== 'en' ? item.label2 : item.label }}</li>

        <li class="nav-item" *ngIf="!item.isTitle">

          <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
            <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
            <span class="link-title"> {{ lang !== 'en' ? item.label2 : item.label }}</span>
            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
          </a>

          <ng-container *ngIf="!item.hidden && item.hasPermissions">
            <a class="nav-link nav-link-ref" [routerLink]="item.link" *ngIf="!hasItems(item)" [attr.data-parent]="item.parentId">
              <ng-container *ngIf="item.isIconFeather">
                <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
              </ng-container>
              <ng-container *ngIf="!item.isIconFeather">
                <i class="link-icon {{item.icon}}" *ngIf="item.icon"></i>
              </ng-container>
              <span class="link-title" *ngIf="lang !== 'en'"> {{ item.label2 }}</span>
              <span class="link-title" *ngIf="lang === 'en'"> {{ item.label }}</span>
              <span class="badge bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
            </a>
          </ng-container>

          <ul class="sidebar-nav sub-menu nav-second-level" *ngIf="hasItems(item)" aria-expanded="false">

            <li class="nav-item" *ngFor="let subitem of item.subItems" [ngClass]="{'side-nav-item': hasItems(subitem)}">
              <a class="nav-link side-nav-link-a-ref" *ngIf="hasItems(subitem)" href="javascript:void(0);">
                <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                <span class="link-title"> {{ subitem.label }}</span>
                <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
              </a>

              <a class="nav-link nav-link-ref" [routerLink]="subitem.link" *ngIf="!hasItems(subitem)"
                 [attr.data-parent]="subitem.parentId">
                <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                {{ subitem.label }}
              </a>

              <ul class="sidebar-nav sub-menu nav-third-level" *ngIf="hasItems(subitem)" aria-expanded="false">
                <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                  <a class="nav-link nav-link-ref" [routerLink]="subSubitem.link" [routerLinkActive]="['active']"
                     [attr.data-parent]="subSubitem.parentId">
                    <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                    {{ subSubitem.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>

    <!--- Sidemenu end -->

  </div>
</nav>
<nav class="settings-sidebar">
  <div class="sidebar-body">
    <a href="" class="settings-sidebar-toggler" (click)="toggleSettingsSidebar($event)">
      <i class="icon feather icon-settings"></i>
    </a>
    <h6 class="text-muted mb-2">Sidebar:</h6>
    <div class="mb-3 pb-3 border-bottom">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="sidebarThemeSettings" [(ngModel)]="theme" (change)="onSidebarThemeChange($event)" id="sidebarLight" value="sidebar-light">
          <i class="input-frame"></i>
          Light
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input type="radio" class="form-check-input" name="sidebarThemeSettings" [(ngModel)]="theme" (change)="onSidebarThemeChange($event)" id="sidebarDark" value="sidebar-dark">
          <i class="input-frame"></i>
          Dark
        </label>
      </div>
    </div>
  </div>
</nav>
