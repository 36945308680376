import { Injectable } from '@angular/core';
import Swal from "sweetalert2";
import { Location } from '@angular/common';
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
//
// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import * as printJS from 'print-js';
import { StorageService } from "./storage.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { addSeconds, differenceInCalendarWeeks, startOfYear } from 'date-fns';
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { excelPost } from '../shared/interfaces/excelPost';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.prod';

const state = {
  settingsGeneral: []
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private collectionName = '/assets/images/categories/icons/';
  dataClone: any[] = [];
  language_COMMON_SERVICE: any = {};

  constructor(
    private _location: Location,
    private service: HttpService,
    private router: Router,
    private storageS: StorageService,
    private auth: AuthService,
    private workbook: Workbook,
    private translate: TranslateService,
  ) {
    this.setLanguage();
  }

  setLanguage() {
    let lang = localStorage.getItem('language_code') ? localStorage.getItem('language_code') : environment.default_language;

    this.translate.getTranslation(lang).subscribe(res => {
      this.translate.setTranslation(lang, {
        ...res
      });

      this.language_COMMON_SERVICE = res.COMMON_SERVICE;

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        lang = event.lang;
        this.translate.setTranslation(lang, {
          ...event.translations
        });
        this.language_COMMON_SERVICE = event.translations.COMMON_SERVICE;
      })
    })
    
  }

  alertModal(txt = '', type = 2) {
    switch (type) {
      case 1:
        Swal.fire({
          icon: 'info',
          title: `${this.language_COMMON_SERVICE.alertModal.case1.title1} ${txt} ${this.language_COMMON_SERVICE.alertModal.case1.title2}`,
          showConfirmButton: false,
          backdrop: 'static',
          allowOutsideClick: false,
        })
        break;
      case 2:
        Swal.fire({
          icon: 'success',
          title: this.language_COMMON_SERVICE.alertModal.case2.title,
          html: `${txt} ${this.language_COMMON_SERVICE.alertModal.case2.html}`,
          showConfirmButton: false,
          timer: 1.5e3,
        })
        break;
      case 3:
        Swal.fire('Opps', `${this.language_COMMON_SERVICE.alertModal.case3.text1} ${txt} ${this.language_COMMON_SERVICE.alertModal.case2.text2}`, 'error');
        break
    }
  }

  alertOutStock(stock) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: this.language_COMMON_SERVICE.alertOutStock.text + stock,
      showConfirmButton: false,
      timer: 2.5e3,
    })
  }

  goBack() {
    // console.log('this.location: ', this._location.back);
    
    this._location.back();
  }

  alertPermissionDenied(page: string) {
    this.router.navigate(['/']).then(() =>
      Swal.fire({
        icon: 'error',
        title: this.language_COMMON_SERVICE.alertPermissionDenied.title,
        text: this.language_COMMON_SERVICE.alertPermissionDenied.text + page,
      })
    );
  }

  // convertSecondsToDate(seconds: number): string {
  //   const date = new Date(seconds * 1e3);
  //   const day = date.getDate().toString().padStart(2, '0');
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const year = date.getFullYear().toString();
  //
  //   let hours = date.getHours();
  //   let ampm = hours < 12 ? 'am' : 'pm';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // convert 0 to 12
  //   const minutes = date.getMinutes().toString().padStart(2, '0');
  //
  //   return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  // }

  // convertSecondsToDate(seconds: number): string {
  //   const date = new Date(seconds * 1e3);
  //   const formatter = new Intl.DateTimeFormat('en-US', {
  //     timeZone: 'America/El_Salvador',
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true,
  //   });
  //   return formatter.format(date);
  // }

  convertSecondsToDate(seconds: number): string {
    const date = new Date(seconds * 1e3);
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/El_Salvador',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const formattedDate = formatter.format(date);
    const [datePart, timePart] = formattedDate.split(', ');
    const [month, day, year] = datePart.split('/');
    return `${year}/${month}/${day}, ${timePart}`;
  }

  formattedNumber(number: string): string {
    return number.slice(0, 4) + ' ' + number.slice(4);
  }

  formattedDate() {
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const WEEKS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let now = new Date();
    return WEEKS[now.getDay()] + ", " + now.getDate() + " of " + MONTHS[now.getMonth()] + " of " + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes();
  }

  /*generatePDF(html) {
    const htmlContent = '<h1>Hello, world!</h1><p>This is some HTML content.</p>';

    // Convierte el HTML en un objeto de definición de documento usando htmlToPdfmake
    const documentDefinition = {
      content: [htmlContent]
    };

    pdfMake.createPdf(documentDefinition).open();
  }*/

  generatePDF(html) {
    // Define el contenido HTML a imprimir
    const htmlContent = `
      <h1>Hello, world!</h1>
      <p>This is some HTML content.</p>
    `;

    // Define las opciones de impresión, incluyendo el tamaño de papel
    const options: printJS.Configuration = {
      printable: htmlContent,
      type: 'html',
    };

    // Llama a la función printJS() con las opciones de impresión
    printJS(options);
  }

  uploadFile(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storageS.updatePhotoWeb(this.collectionName, data.file, data.name, Date.now()).then(data => {
        resolve(data);
      }).catch(err => {
        reject(err);
      })
    })
  }

  alertLoading() {
    Swal.fire({
      html: '<br>  <div class="spinner-border text-dark" role="status">' +
        '    <span class="visually-hidden"></span>' +
        '  </div> <br> <br> loading... ',
      showConfirmButton: false,
      backdrop: 'static',
      allowOutsideClick: false,
    })
  }

  uidUser() {
    this.auth.getUser2().then(async userLocal => {
      return userLocal.uid;
    }, (e) => { });
  }

  getOneSettingsApp(settingName: string): Observable<any> {
    return this.service.sendGET_OneSettingsApp(settingName);
  }

  getWeekNumberFromSeconds(seconds: number): string {
    // Convierte los segundos en una fecha
    const date = addSeconds(startOfYear(new Date()), seconds);

    const year = this.obtenerAnioDesdeTimestamp(seconds);

    // Obtiene el número de semana del año
    const weekNumber = differenceInCalendarWeeks(date, startOfYear(date)) + 1;

    // Formatea y retorna el número de semana con cero inicial si es menor que 10, esto nos ayuda para el order by en el datatable
    return `${year} Week ${weekNumber < 10 ? `0${weekNumber}` : weekNumber}`;
  }

  obtenerAnioDesdeTimestamp(timestamp: number): number {
    // Multiplica el timestamp por 1000 para convertirlo en milisegundos
    const date = new Date(timestamp * 1000);
    // Usa el método getFullYear() para obtener el año
    return date.getFullYear();
  }

  /** 
   *          _________________________________________
   *         |                                         |
   *         | POST PARA LA EXPORTACIÓN A EXCEL .XLSX  |
   *         |_________________________________________|
   *         
   * dataTable = {
   *    title: string,                  nombre del documento y hoja en .xlsx
   *    clientName: string,             nombre del cliente
   *    columns: Array [{               edicion de las columnas
   *        name: string,               ubicación de las columnas Eje: A, B, C ...
   *        width: number               largo de la celda 
   *    }],
   *    columnsMerge: Array [{          fusión de columnas
   *        name: string,               ubicación de las columnas Eje: A1:B1, C1:D1 ...
   *    }],
   *    cellTitleFormats: Array,        estilo del ecabezado Eje: ['B2', 'D2']
   *    headerData: Object {            info del encabezado
   *        headerColumn: string,       edicion del ecabezado Eje: A1:B1
   *        row: number,                número de fila del encabezado
   *        height: number              Alto del encabezado
   *    },
   *    subHeaders: Object {            info de los encabezados de la tabla
   *        row: number,                número de fila de los encabezados de la tabla
   *        data: Array                 encabezados de la tabla partiendo de la primera celda de la fila Eje: ["", "Name", "Email", "Phone", "Validated phone", "Date Register"]
   *        cellFormats: Array,         estilos de los encabezados de la tabla partiendo de la primera celda de la fila Eje: ['B3', 'C3', 'D3', 'E3', 'F3']
   *        color: string,              color de fondo para los encabezados de la tabla
   *    }
   *    dataClone: Array,               información de la tabla columnas segun el numero de encabezados
   *    bodyData: Object {              info del cuerpo
   *        row: number,                número de fila donde inicia el cuerpo de la información
   *        height: number              Alto de cada fila del cuerpo
   *    },
   * }
  **/

  exportTableToExcelJS(dataTable: excelPost) {
    this.dataClone = dataTable.dataClone;
    if (this.dataClone && this.dataClone.length > 0) {
      const title = dataTable.title;
      this.workbook = new Workbook();
      this.workbook.creator = title;
      this.workbook.lastModifiedBy = dataTable.clientName;
      this.workbook.created = new Date();

      this.createTable(dataTable);

      this.workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data]);
        fs.saveAs(blob, title + '.xlsx')
      })
    } else {
      Swal.fire('Opps', this.language_COMMON_SERVICE.exportTableToExcelJS.text, 'info');
    }
  }

  private createTable(dataTable): void {
    const sheet = this.workbook.addWorksheet(dataTable.title);

    dataTable.columns.forEach(element => {
      sheet.getColumn(element.name).width = element.width;
    });

    dataTable.columnsMerge.forEach(element => {
      sheet.mergeCells(element.name);
    });

    const titleCell = sheet.getCell(dataTable.headerData.headerColumn);
    titleCell.value = 'Client: ' + dataTable.clientName;
    titleCell.style.font = { bold: true, size: 24 };
    titleCell.style.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    const cellTitleFormats = dataTable.cellTitleFormats;

    cellTitleFormats.forEach(cell => {
      const titleCellFormat = sheet.getCell(cell);
      titleCellFormat.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    sheet.getRow(dataTable.headerData.row).height = dataTable.headerData.height;

    const headerRow = sheet.getRow(dataTable.subHeaders.row);
    headerRow.values = dataTable.subHeaders.data;

    const cellFormats = dataTable.subHeaders.cellFormats;

    cellFormats.forEach(cell => {
      const titleCellFormat = sheet.getCell(cell);
      titleCellFormat.style = {
        font: { bold: true, size: 15, color: { argb: 'ffffff' } },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: dataTable.subHeaders.color } },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

      };
    });

    const rowsToInsert = sheet.getRows(dataTable.bodyData.row, this.dataClone.length);
    let startRow = dataTable.bodyData.row;

    // Iterar sobre los datos clonados de forma síncrona para asegurar el orden
    for (let i = 0; i < this.dataClone.length; i++) {
      const element = this.dataClone[i];
      const row = rowsToInsert[i];

      row.values = element;

      const cellFormats2 = dataTable.columns.map((column, index) => index > 0 ? column.name + startRow : null).filter(cell => cell !== null);

      // Iterar sobre los formatos de celda de forma síncrona
      for (const cell of cellFormats2) {
        const titleCellFormat = sheet.getCell(cell);
        titleCellFormat.style = {
          font: { size: 12, color: { argb: '000000' } },
          alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
          border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        };
      }

      sheet.getRow(startRow).height = dataTable.bodyData.height;

      startRow++;
    }
  }

  async getBase64ImageFromUrl(imageUrl) {
    // console.log("Downloading image...");
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })

    return result
  }

}
